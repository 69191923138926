import React from 'react'
import _ from 'lodash'
import { Form } from 'semantic-ui-react'
import { Field, ErrorMessage } from 'formik'

export default ({ ...props }) => <Field component={DropdownWrapper} {...props} />

const DropdownWrapper = ({
  form: { touched, errors, isSubmitting, setFieldValue, setFieldTouched, values },
  field,
  ...props
}) => {
  const { name } = field
  const { label, required, disabled, options, onChange, ...rest } = props
  // handle on selected value
  const handleValueChange = React.useCallback(
    e => {
      const selectedValue = document.getElementById(`${name}`).value
      setFieldValue(name, selectedValue)
      setFieldTouched(name, true)
      onChange && onChange(e, selectedValue)
    },
    [name, onChange, setFieldTouched, setFieldValue]
  )

  return (
    <div className="ui form">
      <Form.Field
        required={required}
        error={_.get(errors, name) && _.get(touched, name)}
        disabled={disabled || isSubmitting}
      >
        <label>{label}</label>
        <select
          name={name}
          onChange={handleValueChange}
          id={name}
          value={_.get(values, name)}
          className="ui fluid selection dropdown"
          {...rest}
        >
          {options.map(opt => {
            return (
              <option value={opt.value} key={opt.key}>
                {opt.text}
              </option>
            )
          })}
        </select>
        <ErrorMessage name={name} render={renderError} />
      </Form.Field>
    </div>
  )
}

const renderError = msg => <div className="ui pointing above prompt label">{msg}</div>
