import React from 'react'
import { navigate } from 'gatsby'
import { Formik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { Form, Grid, Message, Progress, Button, Icon, Accordion } from 'semantic-ui-react'
import ReCAPTCHA from 'react-google-recaptcha'
import Vimeo from 'react-player/vimeo'

import SEO from '../components/SEO'
import TextInput from '../components/TextInput'
import Logo from '../images/logos/contego-logo.png'
import DropdownNative from '../components/DropdownNative'

// contact services
import { submitLandingPageForm } from '../services/bullarum.service'

import useTimeoutMessage from '../hooks/useTimeoutMessage'

import { callHandler, findProvince, hasProvinces } from '../ultilities'

import {
  usStates,
  caProvinces,
  mxStates,
  auStates,
  gbStates,
  countries,
  countriesLookupData
} from '../../lookup-data/data'

const stateOption = {
  US: usStates,
  CA: caProvinces,
  MX: mxStates,
  AU: auStates,
  GB: gbStates
}
// define contact us schema for validation
const ContactSchema = Yup.object().shape({
  customerName: Yup.string()
    .required('Your name is required')
    .min(2, 'Invalid input')
    .max(25, 'Invalid input')
    .trim(),
  phoneNumber: Yup.string()
    .matches(/^[1-9]{1}[0-9]{9}$/, 'Invalid input')
    .max(10, 'Invalid input')
    .required('Please enter your phone number!!!'),
  email: Yup.string().email('Invalid email format'),
  businessName: Yup.string().nullable(),
  pedicureSpaChairs: Yup.number().integer('Invalid input!!!'),
  businessAddress: Yup.object()
    .default(null)
    .nullable()
    .shape({
      address1: Yup.string().required('Address1 is required'),
      address2: Yup.string(),
      city: Yup.string().required('City is required'),
      provinceCode: Yup.string().when('countryCode', (countryCode, schema) => {
        hasProvinces(countryCode) ? schema.required('State/Province is required') : schema
      }),
      zip: Yup.string().when('countryCode', (countryCode, schema) => {
        hasProvinces(countryCode) ? schema.required('Zip code is required') : schema
      }),
      countryCode: Yup.string().required('Country is required')
    })
})

const defaultValues = {
  customerName: '',
  phoneNumber: '',
  email: '',
  businessName: '',
  pedicureSpaChairs: 0,
  businessAddress: {
    address1: '',
    address2: '',
    city: '',
    provinceCode: '',
    zip: '',
    countryCode: 'US'
  }
}

const chairVideos = [
  {
    index: 0,
    videoLink: 'https://player.vimeo.com/video/440180506',
    model: 'NOVO'
  },
  {
    index: 1,
    videoLink: 'https://player.vimeo.com/video/440180549',
    model: 'TERGO'
  },
  {
    index: 2,
    videoLink: 'https://player.vimeo.com/video/440180622',
    model: 'VSPORT I'
  },
  {
    index: 3,
    videoLink: 'https://player.vimeo.com/video/440180584',
    model: 'VSPORT II'
  },
  {
    index: 4,
    videoLink: 'https://player.vimeo.com/video/440180473',
    model: 'MAGNA'
  },
  {
    index: 5,
    videoLink: 'https://player.vimeo.com/video/440180454',
    model: 'VIP BENCH'
  }
]

const paulVideos = [
  {
    index: 0,
    videoLink: 'https://player.vimeo.com/video/440109753',
    des: 'Introducing the best spa sanitation technology',
    model: 'CONTÉGO DISPOSABLE JET'
  },
  {
    index: 1,
    videoLink: 'https://player.vimeo.com/video/440110979',
    des: 'You never have to worry about overflow',
    model: 'INFINITY OVERFLOW'
  },
  {
    index: 2,
    videoLink: 'https://player.vimeo.com/video/440110258',
    des: 'Conveniently splits liner allowing water to drain out',
    model: 'EASY DRAIN'
  },
  {
    index: 3,
    videoLink: 'https://player.vimeo.com/video/440110466',
    des: 'Other features that makes the spa chair durable and complete',
    model: 'OTHERS'
  },
  {
    index: 4,
    videoLink: 'https://player.vimeo.com/video/440109343',
    des: 'Contego Spa is your solution to salon success!',
    model: 'WE CARE'
  }
]

const faqs = [
  {
    index: 0,
    question: 'Can I see how Contégo Spa Chairs look inside existing salons?',
    url: 'https://player.vimeo.com/video/439908167'
  },
  {
    index: 1,
    question: 'Does Contégo Sale other products?',
    url: 'https://player.vimeo.com/video/439408088'
  }
]

const InfoPage = () => {
  const recaptchaRef = React.createRef()

  const [activeIndex, setActiveIndex] = React.useState(0)

  const [errorMessage, setErrorMessage] = useTimeoutMessage('')
  const [warningMessage, setWarningMessage] = useTimeoutMessage('')
  const [successMessage, setSuccessMessage] = useTimeoutMessage('')

  const executeRecaptcha = () => {
    recaptchaRef.current.execute()
  }

  const handleExpired = () => {}

  const handleSubmission = React.useCallback(
    (values, actions) => {
      actions.setSubmitting(true)
      const { customerName, phoneNumber, businessName, email, pedicureSpaChairs } = values

      // sanitize data before sending
      if (recaptchaRef.current.getValue()) {
        const sanitizedData = {
          customerName: _.escape(_.trim(customerName)),
          phoneNumber: _.escape(_.trim(phoneNumber)),
          status: 'new',
          pedicureSpaChairs,
          businessName,
          email
        }

        const { address1, address2, city, countryCode, provinceCode, zip } = values.businessAddress

        sanitizedData.businessAddress = {
          address1: _.trim(address1).toLowerCase(),
          address2: _.trim(address2).toLowerCase(),
          city: _.trim(city).toLowerCase(),
          provinceCode: hasProvinces(countryCode) ? provinceCode : '',
          province: hasProvinces(countryCode)
            ? findProvince(countryCode, provinceCode)
            : provinceCode,
          zip: zip !== '' ? _.trim(zip).toLowerCase() : '',
          countryCode,
          country: countriesLookupData[countryCode]
        }

        // submitting form value
        submitLandingPageForm(sanitizedData)
          .then(res => {
            setSuccessMessage(res.data.message)
          })
          .catch(error => {
            if (error.response) {
              setErrorMessage(error.response.data, 5000)
            } else {
              setErrorMessage('Something wrong happened. Please try again later!!!', 5000)
            }
          })
          .finally(() => {
            actions.setSubmitting(false)
            actions.resetForm()
            navigate('/vi/thank-you')
          })
      }
    },
    [recaptchaRef, setErrorMessage, setSuccessMessage]
  )

  const handleCallNow = e => {
    const currentHour = Number(
      new Date()
        .toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hour12: false })
        .split(':')[0]
    )

    if (currentHour >= 8 && currentHour <= 21) {
      callHandler(e.currentTarget.dataset.phoneNumber)
      navigate('/vi/thank-you')
    } else {
      // eslint-disable-next-line no-alert
      setWarningMessage(
        'Liên lạc ngoài giờ làm việc!!! Anh chị vui lòng để lại thông tin bên dưới, nhân viên tư vấn sẽ liên lạc. Giờ làm việc của nhân viên tư vấn 8AM-9PM (Giờ California)',
        20000
      )
    }
  }

  const handleFAQClick = React.useCallback(
    (e, titleProps) => {
      const { index } = titleProps
      const newIndex = activeIndex === index ? -1 : index
      setActiveIndex(newIndex)
    },
    [activeIndex]
  )

  return (
    <div className="container-fluid">
      <SEO
        title="Why Choose CONTEGO Spa?"
        description="Contego Pedicure Spa Chairs protect clients from cross-conm"
        meta={[
          {
            name: 'keywords',
            content: 'pedicure spa chair, spa chair, nails sallon, beauty, contego'
          }
        ]}
      />

      <div className="row ">
        <div className="col ">
          <Grid columns="1" stackable className="my-2 rounded">
            <Grid.Column verticalAlign="middle">
              <img
                src={Logo}
                alt="contego logo"
                className="mx-auto d-block"
                width="150px"
                height="auto"
              />
              <h1 className="text-center py-2 text-secondary mb-0">WHY CHOOSE CONTÉGO SPA?</h1>
              <p className="text-center px-5">“Protecting clients from cross-contamination”</p>
            </Grid.Column>
          </Grid>

          <Grid stackable columns="16" padded className="py-4 bg-light rounded">
            <Grid.Column
              widescreen="4"
              largeScreen="4"
              computer="4"
              tablet="16"
              mobile="16"
              className="d-flex flex-column justify-content-center"
            >
              <h2>DO YOU KNOW HOW THE REUSABLE JET WORKS?</h2>

              <p>A real threat to sanitation practices</p>
            </Grid.Column>

            <Grid.Column widescreen="12" largeScreen="12" computer="12" tablet="16" mobile="16">
              <div className="player-wrapper">
                <Vimeo
                  className="react-player"
                  url="https://player.vimeo.com/video/437274985"
                  playsinline
                  controls
                  width="100%"
                  height="100%"
                />
              </div>
            </Grid.Column>
          </Grid>
        </div>
      </div>

      <Grid stackable columns="16" padded className="py-4 rounded">
        <Grid.Column
          widescreen="4"
          largeScreen="4"
          computer="4"
          tablet="16"
          mobile="16"
          className="d-flex flex-column justify-content-center"
        >
          <h2>WHY REUSABLE JETS CAUSE PROBLEMS</h2>

          <p>
            Improper cleaning of Reusable Jets create an ideal environment for bacteria and fungi to
            live and spread from one session to another.
          </p>
        </Grid.Column>

        <Grid.Column widescreen="12" largeScreen="12" computer="12" tablet="16" mobile="16">
          <div className="player-wrapper">
            <Vimeo
              className="react-player"
              url="https://player.vimeo.com/video/433678040"
              playsinline
              controls
              width="100%"
              height="100%"
            />
          </div>
        </Grid.Column>
      </Grid>

      <Grid stackable columns="16" padded className="py-4 bg-light rounded">
        <Grid.Column
          widescreen="4"
          largeScreen="4"
          computer="4"
          tablet="16"
          mobile="16"
          className="d-flex flex-column justify-content-center"
        >
          <h2 className="mb-0">
            CONTÉGO’S 100% <strong className="text-secondary">DISPOSABLE JET</strong>
          </h2>
          <h2 className="mt-0">IS THE SOLUTION</h2>
          <p>
            The 100% Disposable Jet is a single use jet which will be disposed of after each
            session. You will never worry about fungus or bacteria again!
          </p>
        </Grid.Column>

        <Grid.Column widescreen="12" largeScreen="12" computer="12" tablet="16" mobile="16">
          <div className="player-wrapper">
            <Vimeo
              className="react-player"
              url="https://player.vimeo.com/video/433679019"
              playsinline
              controls
              width="100%"
              height="100%"
            />
          </div>
        </Grid.Column>
      </Grid>

      <Grid columns="1" padded className="my-4 py-4 rounded">
        <Grid.Column>
          <h2>PEOPLE FEEL SAFE USING CONTÉGO&lsquo;S DISPOSABLE JET</h2>

          <p>
            People share their experience after choosing Contégo’s Disposable Jet for their pedicure
            session.
          </p>

          <div className="vimeo-video-container">
            <iframe
              title="Disposable Jet"
              src="https://player.vimeo.com/video/433493078"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              playsinline
              className="rounded vimeo-video"
            />
          </div>
        </Grid.Column>
      </Grid>

      <div className="row mt-4 py-4 bg-light">
        <div className="col">
          <Grid columns="1" stackable className="pb-4">
            <Grid.Column>
              <h2 className="w-100 text-center pt-4">
                <strong>OUR SPA CHAIR MODELS</strong>
                <p>Practical Eligance Designs</p>
              </h2>
            </Grid.Column>
          </Grid>

          <div className="vimeo-gallery">
            {chairVideos.map(({ i, videoLink, model }) => {
              return (
                <div className="shadow rounded mx-2 my-2" key={`${model}-${i}`}>
                  <iframe
                    title="Infinity Overflow"
                    src={videoLink}
                    className="vimeo-iframe"
                    frameBorder="0"
                    allow="fullscreen"
                    playsinline
                    allowFullScreen
                  />
                  <div className="py-4">
                    <h2 className="text-center px-2 mb-0">
                      <strong>{model}</strong>
                    </h2>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className="row mt-4 py-4 bg-light">
        <div className="col">
          <Grid columns="1" stackable className="pb-4">
            <Grid.Column>
              <h2 className="w-100 text-center pt-4">
                <strong>SAFER, FASTER & CLEANER</strong>
              </h2>
            </Grid.Column>
          </Grid>

          <div className="vimeo-gallery">
            {paulVideos.map(({ i, videoLink, model, des }) => {
              return (
                <div className="shadow rounded mx-2 my-2" key={`${model}-${i}`}>
                  <div className="player-wrapper">
                    <Vimeo
                      className="react-player"
                      url={videoLink}
                      controls
                      playsinline
                      width="100%"
                      height="100%"
                    />
                  </div>

                  <div className="py-4">
                    <h2 className="text-center px-2 mb-0">
                      <strong>{model}</strong>
                    </h2>
                    <p className="px-4 text-center">{des}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className="row py-4">
        <div className="col ">
          <div className="px-3 py-4 text-secondary">
            <h1>
              CONTEGO SPA CHAIR protects your clients from cross-contamination and assists your
              technicians to perform work effectively.
              <Button
                className="call-now-btn px-4 py-2 mx-2 my-2 rounded-pill text-large text-white shadow"
                data-phone-number="7148958865"
                onClick={handleCallNow}
              >
                714-895-8865 <Icon name="phone" className="m-0" />
              </Button>{' '}
              <strong className="text-primary">
                or leave behind information and our sales consultant will reach out to you.
              </strong>
            </h1>

            {warningMessage !== '' && <Message content={warningMessage} warning />}
          </div>

          <Formik
            initialValues={defaultValues}
            onSubmit={handleSubmission}
            validationSchema={ContactSchema}
          >
            {({ handleSubmit, isSubmitting, isValid, setFieldValue, values }) => (
              <>
                <Form noValidate className="attached fluid segment rounded shadow">
                  {isSubmitting && <Progress percent={100} attached="top" active size="medium" />}
                  {successMessage === '' && (
                    <Grid stackable padded>
                      <Grid.Row columns="3">
                        <Grid.Column>
                          <TextInput
                            name="customerName"
                            label="Name"
                            placeholder="Please enter your name"
                            autoComplete="name"
                            required
                          />
                        </Grid.Column>

                        <Grid.Column>
                          <TextInput
                            name="phoneNumber"
                            label="Phone Number"
                            placeholder="Please enter your phone number"
                            autoComplete="phone"
                            required
                          />
                        </Grid.Column>

                        <Grid.Column>
                          <TextInput
                            name="email"
                            label="Email"
                            placeholder="Please enter your email"
                            autoComplete="email"
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row columns="2">
                        <Grid.Column>
                          <TextInput
                            name="businessName"
                            label="Business Name"
                            placeholder="Please enter your business name"
                          />
                        </Grid.Column>

                        <Grid.Column>
                          <TextInput
                            name="pedicureSpaChairs"
                            label="Number of Spa Chairs"
                            placeholder="please let us know how many spa chairs do you need"
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column>
                          <Grid stackable columns="1">
                            <Grid.Column>
                              <h2>Business Address</h2>
                            </Grid.Column>

                            <Grid.Column>
                              <TextInput
                                name="businessAddress.address1"
                                label="Address 1"
                                placeholder=""
                                required
                              />
                            </Grid.Column>

                            <Grid.Column>
                              <TextInput
                                name="businessAddress.address2"
                                label="Address 2"
                                placeholder="Apartment, suite, unit, building, floor, etc..."
                              />
                            </Grid.Column>

                            <Grid.Column>
                              <TextInput
                                name="businessAddress.city"
                                label="City"
                                placeholder=""
                                required
                              />
                            </Grid.Column>
                          </Grid>

                          <Grid stackable columns="3">
                            <Grid.Column>
                              <DropdownNative
                                name="businessAddress.countryCode"
                                label="Country"
                                options={countries}
                                onChange={() =>
                                  setFieldValue('businessAddress.provinceCode', undefined)
                                }
                                required
                              />
                            </Grid.Column>

                            <Grid.Column>
                              {hasProvinces(values.businessAddress.countryCode) ? (
                                <DropdownNative
                                  name="businessAddress.provinceCode"
                                  label="State/Province"
                                  options={stateOption[values.businessAddress.countryCode]}
                                  required
                                />
                              ) : (
                                <TextInput
                                  name="businessAddress.provinceCode"
                                  label="State/Province"
                                  placeholder=""
                                />
                              )}
                            </Grid.Column>

                            <Grid.Column>
                              <TextInput
                                name="businessAddress.zip"
                                label="ZIP Code"
                                placeholder=""
                                required={hasProvinces(values.businessAddress.countryCode)}
                              />
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>

                      {isValid && (
                        <Grid.Row>
                          <Grid.Column>
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              size="invisible"
                              sitekey="6Le0asMUAAAAALU8Bsu2PvNdUevkl9mM4s8YmT7F"
                              onChange={handleSubmit}
                              onExpired={handleExpired}
                              badge="inline"
                            />
                          </Grid.Column>
                        </Grid.Row>
                      )}

                      <Grid.Row>
                        <Grid.Column>
                          <Button
                            type="button"
                            primary
                            onClick={executeRecaptcha}
                            disabled={isSubmitting || !isValid}
                          >
                            Send
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  )}
                </Form>

                {errorMessage !== '' && (
                  <Message
                    attached="bottom"
                    negative
                    content="Xin lỗi hiện tại hệ thống đang gặp vấn đề trục trặc kỹ thuật. Quý anh chị vui lòng liên hệ trực tiếp với chúng tôi (714) 895-8865!!!"
                  />
                )}

                {successMessage !== '' && (
                  <Message attached="bottom" positive>
                    <h1>THANK YOU!!!</h1>
                    <p>Nhân viên tư vấn sẽ liên lạc với quý anh chị sớm.</p>
                  </Message>
                )}
              </>
            )}
          </Formik>
        </div>
      </div>

      <Grid columns="1" className="py-4 bg-light mt-4">
        <Grid.Column>
          <h1 className="text-secondary">FAQ</h1>
        </Grid.Column>

        <Grid.Column>
          <Accordion fluid>
            {faqs.map((faq, index) => {
              return (
                <div key={faq.question}>
                  <Accordion.Title
                    active={index === activeIndex}
                    index={faq.index}
                    data-index={faq.index}
                    onClick={handleFAQClick}
                  >
                    <h3>
                      <Icon name="dropdown" />
                      {faq.question}
                    </h3>
                  </Accordion.Title>

                  <Accordion.Content active={index === activeIndex}>
                    <div className="player-wrapper">
                      <Vimeo
                        className="react-player"
                        url={faq.url}
                        playsinline
                        controls
                        width="100%"
                        height="100%"
                      />
                    </div>
                  </Accordion.Content>
                </div>
              )
            })}
          </Accordion>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default InfoPage
